var render = function render(){
  var _vm$form$permissionId, _vm$form$permissionId2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('TabTitle', {
    staticClass: "mb-4",
    attrs: {
      "icon": "fa-plus",
      "action-position": "right"
    },
    scopedSlots: _vm._u([{
      key: "sub-title",
      fn: function () {
        return [_vm._v(_vm._s(_vm.$route.params.roleId ? 'Edit' : 'Create') + " a new role to this company.")];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function () {
        return [_c('router-link', {
          staticClass: "btn btn-default",
          attrs: {
            "to": {
              path: `/company/roles`
            }
          }
        }, [_vm._v("Back to roles "), _c('i', {
          staticClass: "fa fa-arrow-right ml-1"
        })])];
      },
      proxy: true
    }])
  }, [[_vm._v(_vm._s(_vm.$route.params.roleId ? 'Edit' : 'Create') + " Role")]], 2), _vm.loading ? _c('Spinner') : _c('div', {
    staticClass: "block-content"
  }, [_vm.error ? _c('div', {
    staticClass: "alert alert-danger"
  }, [_vm._v(_vm._s(_vm.error))]) : _vm._e(), _c('RoleForm', {
    attrs: {
      "error": _vm.validationErrors
    },
    model: {
      value: _vm.form,
      callback: function ($$v) {
        _vm.form = $$v;
      },
      expression: "form"
    }
  }), _c('div', {
    staticClass: "text-right pb-4"
  }, [((_vm$form$permissionId = _vm.form.permissionIds) === null || _vm$form$permissionId === void 0 ? void 0 : _vm$form$permissionId.length) === 0 ? _c('p', {
    staticClass: "text-danger"
  }, [_c('i', {
    staticClass: "fa fa-times-circle fa-fw"
  }), _vm._v("Please select a permission")]) : _vm._e(), _c('button', {
    staticClass: "btn btn-primary",
    attrs: {
      "type": "submit",
      "disabled": _vm.loading || ((_vm$form$permissionId2 = _vm.form.permissionIds) === null || _vm$form$permissionId2 === void 0 ? void 0 : _vm$form$permissionId2.length) === 0
    },
    on: {
      "click": _vm.onClickSubmit
    }
  }, [_vm.loading ? _c('span', [_c('i', {
    staticClass: "fa fa-spin fa-spinner mr-1"
  }), _vm._v(" " + _vm._s(_vm.$route.params.roleId ? 'Updating' : 'Creating') + "...")]) : _c('span', [_c('i', {
    staticClass: "fa fa-check-circle mr-1"
  }), _vm._v(" Submit")])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }