var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('FormGroup', {
    attrs: {
      "id": "name",
      "type": "text",
      "disabled": _vm.disabled,
      "label": "Name"
    },
    model: {
      value: _vm.form.name,
      callback: function ($$v) {
        _vm.$set(_vm.form, "name", $$v);
      },
      expression: "form.name"
    }
  }), _c('FormGroup', {
    attrs: {
      "id": "description",
      "type": "textarea",
      "disabled": _vm.disabled,
      "label": "Description"
    },
    model: {
      value: _vm.form.description,
      callback: function ($$v) {
        _vm.$set(_vm.form, "description", $$v);
      },
      expression: "form.description"
    }
  }), _vm.permissions.length === 0 ? _c('div', [_c('label', [_vm._v("Permissions")]), _c('div', {
    staticClass: "alert alert-warning"
  }, [_vm._v("No permissions found.")])]) : _c('FormGroup', {
    attrs: {
      "id": "permissionIds",
      "type": "select-array-list",
      "label": "Permissions",
      "error": _vm.error['permissionIds'],
      "options": _vm.permissionOptions
    },
    model: {
      value: _vm.form.permissionIds,
      callback: function ($$v) {
        _vm.$set(_vm.form, "permissionIds", $$v);
      },
      expression: "form.permissionIds"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }